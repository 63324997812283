<template>
  <vx-card>
    <!-- REMOVE POP UP -->
    <template>
      <!-- REMOVE POP UP -->
      <vs-popup
        classContent="popup-example label-font"
        title="ลบข้อมูล"
        :active.sync="removePopupActive"
      >
        <!--  รหัส  -->
        <vs-row style="font-weight:bold; margin-top:30px;" class="row-form" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label px-2"
            style="padding-left: 20px 30px;"
          >ลบ {{ labelname }} {{ this.theInfo }}</vs-col>
        </vs-row>

        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
          >
            <vs-button @click="remove()" style="margin:10px;" color="success" type="filled">ตกลง</vs-button>
            <vs-button
              @click="closeRemovePanel()"
              style="margin:10px;"
              color="danger"
              type="filled"
            >ยกเลิก</vs-button>
          </vs-col>
        </vs-row>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <!-- UPDATE POP UP -->
    <template>
      <!-- UPDATE POP UP -->
      <vs-popup
        classContent="popup-example label-font"
        title="เปลี่ยนข้อมูล"
        :active.sync="updatePopupActive"
      >
        <!--  รหัส  -->
        <vs-row style="font-weight:bold; margin-top:30px;" class="row-form" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
          >{{ labelname }}</vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
            class="form-label px-2"
          >
            {{ configitem }}
            <!-- <vs-input class="w-full" v-model="configitem" /> -->
          </vs-col>
        </vs-row>
        <!--  เปลี่ยนสถานะของรายการเช่าสินค้า  -->
        <!--  Add this to data part  (import vSelect from "vue-select")     "v-select": vSelect  -->
        <!--  :select >>> ...Selected /  :options >>> ...Options -->

       <!-- สิทธิ์ผู้ใช้งาน -->

        <vs-row class="row-form" vs-w="12" style="margin-top:20px;margin-bottom:20px;">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
          >สิทธิ์ผู้ใช้งาน</vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="7"
            vs-sm="7"
            vs-xs="7"
            class="form-label px-2"
            style="word-wrap: break-word;"
          >
            <div class="grid-layout-container alignment-block" style="width:100%;">
              <vs-row vs-w="12">

                      <v-select style="width:85%;" v-model="userRole"  :options="accessRight" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
              </vs-row>
            </div>

          </vs-col>
        </vs-row>

        <!-- สาขาที่สังกัด -->
        <vs-row class="row-form py-2" vs-w="12" style="margin-top:20px;margin-bottom:20px;">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
          >สาขา</vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="7"
            vs-sm="7"
            vs-xs="7"
            class="form-label px-2"
            style="word-wrap: break-word;"
          >
            <div class="grid-layout-container alignment-block" style="width:100%;">
              <vs-row vs-w="12">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-lg="4"
                  vs-sm="4"
                  vs-xs="12"
                  style="margin-top: 10px;"
                  v-for="(brn, index) in branchList"
                  :key="index"
                >
                  <vs-checkbox v-model="userBranch" :vs-value="brn.branchId">
                    {{
                    brn.branchName
                    }}
                  </vs-checkbox>
                </vs-col>
              </vs-row>
            </div>

            <!-- <vs-textarea v-model="dec"></vs-textarea> -->
          </vs-col>
        </vs-row>
        <!-- /สาขาที่สังกัด -->

        <!-- สถานะผู้ใช้งาน -->
        <vs-row style="margin-top:20px;margin-bottom:30px;" class="row-form" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
          >สถานะ</vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
            class="form-label px-2"
          >
            <vs-switch style="width:50px;" color="success" v-model="theState">
              <span slot="on">เปิด</span>
              <span slot="off">ปิด</span>
            </vs-switch>
          </vs-col>
        </vs-row>

        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
          >
            <vs-button @click="update()" style="margin:10px;" color="success" type="filled">ตกลง</vs-button>
            <vs-button
              @click="closeUpdatePanel()"
              style="margin:10px;"
              color="danger"
              type="filled"
            >ยกเลิก</vs-button>
          </vs-col>
        </vs-row>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <!-- CREATE POP UP -->
    <template>
      <vs-popup
        classContent="popup-example label-font"
        title="เพิ่มข้อมูล"
        :active.sync="createPopupActive"
      >
        <!--  Username  -->
        <vs-row style="font-weight:bold; margin-top:30px;" class="row-form" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
          >{{ labelname }}</vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
            class="form-label px-2"
          >
            <div
              class="grid-layout-container alignment-block"
              style="vertical-align:text-top ;width:100%;"
            >
              <vs-row vs-w="12">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                  class="px-1"
                >
                  <vs-input
                    data-vv-validate-on="blur"
                    v-validate="'required|email'"
                    class="w-full"
                    name="email"
                    v-model="configitem"
                  />
                </vs-col>
              </vs-row>

              <vs-row vs-w="12">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                  class="px-1"
                >
                  <span class="text-danger text-sm">
                    {{
                    errors.first("email")
                    }}
                  </span>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>

        <!--  Password  -->
        <vs-row style="font-weight:bold; margin-top:30px;" class="row-form" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
          >Password</vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
            class="form-label px-2"
          >
            <div
              class="grid-layout-container alignment-block"
              style="vertical-align:text-top ;width:100%;"
            >
              <vs-row vs-w="12">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                  class="px-1"
                >
                  <vs-input
                    data-vv-validate-on="blur"
                    v-validate="'required|min:6|max:15'"
                    type="password"
                    name="password"
                    class="w-full"
                    v-model="password"
                  />
                </vs-col>
              </vs-row>

              <vs-row vs-w="12">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                  class="px-1"
                >
                  <span class="text-danger text-sm">
                    {{
                    errors.first("password")
                    }}
                  </span>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>

        <!-- สิทธิ์ผู้ใช้งาน -->

        <vs-row class="row-form" vs-w="12" style="margin-top:20px;margin-bottom:20px;">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
          >สิทธิ์ผู้ใช้งาน</vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="7"
            vs-sm="7"
            vs-xs="7"
            class="form-label px-2"
            style="word-wrap: break-word;"
          >
            <div class="grid-layout-container alignment-block" style="width:100%;">
              <vs-row vs-w="12">

                      <v-select style="width:85%;" v-model="userRole"  :options="accessRight" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
              </vs-row>
            </div>

          </vs-col>
        </vs-row>

        <!-- สาขาที่สังกัด -->
        <vs-row class="row-form py-2" vs-w="12" style="margin-top:20px;margin-bottom:20px;">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
          >สาขา</vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="7"
            vs-sm="7"
            vs-xs="7"
            class="form-label px-2"
            style="word-wrap: break-word;"
          >
            <div class="grid-layout-container alignment-block" style="width:100%;">
              <vs-row vs-w="12">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-lg="4"
                  vs-sm="4"
                  vs-xs="12"
                  style="margin-top: 10px;"
                  v-for="(brn, index) in branchList"
                  :key="index"
                >
                  <vs-checkbox v-model="userBranch" :vs-value="brn">
                    {{
                    brn.branchName
                    }}
                  </vs-checkbox>
                </vs-col>
              </vs-row>
            </div>

            <!-- <vs-textarea v-model="dec"></vs-textarea> -->
          </vs-col>
        </vs-row>
        <!-- /สาขาที่สังกัด -->

        <vs-row style="margin-top:20px;margin-bottom:30px;" class="row-form" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
          >สถานะ</vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
            class="form-label px-2"
          >
            <vs-switch style="width:50px;" color="success" v-model="theState">
              <span slot="on">เปิด</span>
              <span slot="off">ปิด</span>
            </vs-switch>
          </vs-col>
        </vs-row>

        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
          >
            <vs-button @click="create()" style="margin:10px;" color="success" type="filled">ตกลง</vs-button>
            <vs-button
              @click="createPopupActive = false"
              style="margin:10px;"
              color="danger"
              type="filled"
            >ยกเลิก</vs-button>
          </vs-col>
        </vs-row>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <!-- HEADER -->
    <div class="grid-layout-container alignment-block" style="vertical-align:text-top ;width:100%;">
      <!-- <json-viewer :expand-depth="4" :value="configlist"></json-viewer> -->
      <!--------------------------------------------------------------------->
      <!--  theline  -->
      <vs-row class="row-form" vs-w="12" style="margin-top:30px;">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="4"
          vs-sm="4"
          vs-xs="4"
        >
          <h3 style="margin-bottom: 20px;">{{ labelname }}</h3>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="4"
          vs-sm="4"
          vs-xs="4"
        ></vs-col>

        <!-- SAVE ITEM -->
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="2"
        ></vs-col>
        <!-- SAVE ITEM -->
        <!-- BACK to STOCK -->
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="2"
        >
          <vs-button

            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            style="width:100%; padding-left:0; padding-right:0;"
            color="#7ED321"
            type="filled"
            @click="openCreatePanel()"
          >
            <span>
              <i
                style="font-size:18px; margin-right:5px;vertical-align:middle;"
                class="material-icons"
              >add_circle_outline</i>
            </span>
            <span style="vertical-align:middle;">เพิ่มผู้ใช้งาน</span>
          </vs-button>
        </vs-col>
        <!-- BACK to STOCK -->
      </vs-row>
      <!-- <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="12"
          vs-sm="12"
          vs-xs="12"
          class="form-label px-2"
        >
          <div style="width:100%; height:5px; background-color:#50E3C2;"></div>
        </vs-col>
      </vs-row>-->
      <!--------------------------------------------------------------------->
    </div>
    <!-- HEADER -->

    <vs-table pagination max-items="20" search :data="configlist">
      <template slot="thead">
        <vs-th>รูปภาพ</vs-th>
        <vs-th >username</vs-th>
        <vs-th>สิทธิ์</vs-th>
        <vs-th >อัพเดตล่าสุด</vs-th>
        <vs-th >อัพเดตโดย</vs-th>
        <vs-th >สถานะ</vs-th>
        <vs-th style="text-align:right !important; width:15% ">การจัดการ</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td style="width:15% ; padding-left:10px !important;">
            <img
              class="responsive rounded"
              :src="getUserImagePath(tr['userImage'])"
              alt="user-upload"
              style="width:70px;"
            />
          </vs-td>

          <vs-td style="width:20% ">{{ tr.username }}</vs-td>
          <vs-td style="width:20% ">{{ getAllRole(tr.roleName) }}</vs-td>
          <vs-td style="width:15% ; padding-left:20px;">
            {{
            formatDate(tr.createDate)
            }}
          </vs-td>
          <vs-td style="width:15% ; padding-left:20px;">
            {{
            tr.createBy
            }}
          </vs-td>
          <vs-td style="max-width:100 px; padding-left:20px;">
            <vs-switch
            v-if="userBestRole === 'owner'  || (userBestRole === 'admin' && (eachBestRole(tr.roleName) === 'admin' || eachBestRole(tr.roleName) === 'staff')  ) "
              @click="changeState(tr[idname], indextr)"
              color="success"
              v-model="tr.active"
            >
              <span slot="on">เปิด</span>
              <span slot="off">ปิด</span>
            </vs-switch>
          </vs-td>
          <vs-td style="width:15% text-align:center !important;">
            <div v-if="indextr < (data.length - 1) " >
                <i
                  v-if="userBestRole === 'owner'  || (userBestRole === 'admin' && (eachBestRole(tr.roleName) === 'admin' || eachBestRole(tr.roleName) === 'staff')  ) "
                  @click="openUpdatePanel(tr[idname], indextr)"
                  style="cursor:pointer; color: #5A8EE6;font-size:24px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
                  class="material-icons"
                >edit</i>
                <i
                  v-if="userBestRole === 'owner' || (userBestRole === 'admin' && (eachBestRole(tr.roleName) === 'admin' || eachBestRole(tr.roleName) === 'staff') )   "
                  @click="openRemovePanel(tr[idname], indextr)"
                  style="cursor:pointer; color:#F63B39; font-size:24px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
                  class="material-icons"
                >delete_forever</i>
            </div>
            <!-- <div v-else >
                 <i
                  v-if="  data[data.length - 1]['username'] === currentUser || userBestRole === 'owner'  "
                  @click="openUpdatePanel(tr[idname], indextr)"
                  style="cursor:pointer; color: #5A8EE6;font-size:24px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
                  class="material-icons"
                >edit</i>
            </div> -->
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </vx-card>
</template>

<script>
import shapeFormat from "../../mixins/shapeFormat.js";
import vSelect from 'vue-select'

import JsonViewer from 'vue-json-viewer'

// import {getBestRole} from '@/utils'
export default {
  // props: {
  //   apiname: String,
  //   idname: String,
  //   labelname: String
  // },
  mixins: [shapeFormat],
  data() {
    return {
      currentUser: "",
      accessRight: [], // ["owner", "admin", "staff"]
      branchList: [],
      password: "",
      userImage: "",
      userRole: [],
      userBranch: [],
      roles: [],
      idname: "username",
      apiname: "user",
      labelname: "ผู้ใช้งาน",
      theInfo: "",
      theState: true,
      theIndex: "",
      theID: "",
      configitem: "",
      dec: "",
      createPopupActive: false,
      updatePopupActive: false,
      removePopupActive: false,
      selected: [],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ],
      configlist: []
    };
  },
computed: {
  //  *** BEGIN : 2020-12-12 : Search By branches
    getCurrentPackage(){

      return this.$store.getters["auth/currentPackage"];
    },

    userBestRole() {
      // return JSON.parse(localStorage.getItem('userInfo')).displayName
      return this.$store.getters["auth/getRole"] ;
    },
    // currentUser(){
    //   return this.$store.getters["auth/currentUser"] ;
    // },

  },

  components: {
    'v-select': vSelect,
    JsonViewer
  },

  methods: {

    eachBestRole(roles){
      var ro = null;

      ro =  roles.filter(item => item.roleName === "owner")

        if (ro !== null && ro !== undefined && ro.length > 0)
          return "owner";

        ro = roles.filter(item => item.roleName === "admin")
        if (ro !== null && ro !== undefined && ro.length > 0)
          return "admin";


        ro = roles.filter(item => item.roleName === "satff")
        if (ro !== null && ro !== undefined && ro.length > 0)
          return "satff";


        if (ro === null || ro === undefined) {
          return "staff";
        }

        return "staff";

    },

    async reload() {
      /*****  BEGIN: AXIOS HTTP   *****/
      this.$http
        .get(
          this.$store.state.apiURL + "/api/" + this.apiname,
          this.$store.state.auth.apiHeader
        )
        .then(response => {
          // console.log("user api data >>>> ****%%%$###", response.data);
          if (response.status === 200) {
            this.configlist = response.data;
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: "ไม่สามารถโหลดข้อมูลได้",
            position: "top-center",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        });
      /*****  END: AXIOS HTTP   *****/
    },
    getAllRole(roles) {
      if (!roles) {
        return "";
      }
      var roleStr = "";
      for (let i = 0; i < roles.length; i++) {
        if (i === 0) {
          roleStr = roleStr + roles[i].roleName;
        } else {
          roleStr = roleStr + "  ," + roles[i].roleName;
        }
      }
      return roleStr;
    },
    getUserImagePath(userimage) {
      if (userimage === null || userimage === undefined) {
        return this.$store.state.defaultUserImage;
      } else {
        return this.$store.state.userImageStore + userimage;
      }
    },
    openCreatePanel() {
      if(  this.getCurrentPackage === 's' && this.configlist.length >= 1 )
      {
        this.$swal({
          icon: 'warning',
          title: 'Package s ไม่สามารถเพิ่มผู้ใช้งานใหม่ได้',
        });
        this.closeCreateBranchPanel();
        return;
      }

      if(  this.getCurrentPackage === 'm' && this.configlist.length >= 4 )
      {
          this.$swal({
          icon: 'warning',
          title: 'Package m <br> สร้างผู้ใช้งานสูงสุด 4 บัญชี',
        });
        return;
      }
      this.createPopupActive = true;
      this.resetValue();
    },
    closeCreatePanel() {
      this.createPopupActive = false;
      this.resetValue();
    },
    async create() {
      // UPDATE USER
      if(  this.getCurrentPackage === 's' && this.configlist.length >= 1 )
      {
        this.$swal({
          icon: 'warning',
          title: 'Package s ไม่สามารถเพิ่มผู้ใช้งานใหม่ได้',
        });
        this.closeCreateBranchPanel();
        return;
      }

      if(  this.getCurrentPackage === 'm' && this.configlist.length >= 4 )
      {
          this.$swal({
          icon: 'warning',
          title: 'Package m <br> สร้างผู้ใช้งานสูงสุด 4 บัญชี',
        });
        return;
      }
      var theroles = [];

      if(this.configitem === null || this.configitem === undefined || this.configitem === ""  ){
        this.$swal({
          icon: 'warning',
          title: 'โปรดระบุชื่อผู้ใช้งานในรูปแบบอีเมล',
        });

        return ;
      }
      if (!this.configitem.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)  ){
        this.$swal({
          icon: 'error',
          title: 'โปรดระบุชื่อผู้ใช้งานในรูปแบบอีเมล',
        });
         return ;
      }


      if(this.password.length < 6){
        this.$swal({
          icon: 'warning',
          title: 'ความยาวของ password <br>อย่างน้อย 6 ตัวอักษร',
        });

        return ;
      }

      if(this.userRole.length <= 0){
        this.$swal({
          icon: 'warning',
          title: 'เลือกสิทธิ์ผู้ใช้งานอย่างน้อย 1 สิทธิ์',
        });

        return ;
      }
      if(this.userBranch.length <= 0){
        this.$swal({
          icon: 'warning',
          title: 'เลือกสาขาอย่างน้อย 1 สาขา',
        });

        return ;
      }


      var a_role = { roleName: this.userRole };
       theroles.push(a_role);

      var thebranches = [];
      for (let index = 0; index < this.userBranch.length; index++) {
        thebranches.push(this.userBranch[index]);
      }

      var resp = null ;

      // console.log("theroles >>> ",theroles);

      resp = await this.$http
        .post(
          this.$store.state.apiURL + "/api/" + this.apiname + "/register",
          {
            username: this.configitem,
            password: this.password,
            roleName: theroles,
            branches: thebranches,
            active: this.theState
          },
          this.$store.state.auth.apiHeader
        );
          if (resp.status === 200) {
            this.$vs.notify({
              title: "บันทึกสำเร็จ",
              position: "top-center",
              text: "ได้บันทึกข้อมูลสินค้าเรียบร้อยแล้ว",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success"
            });
            this.reload();
            // console.log("response.data ", response.data);
            this.createPopupActive = false;
          }




      /*****  END: AXIOS HTTP   *****/
    },
    resetValue() {
      this.configitem = "";
      this.password = "";
      this.dec = "";
      this.theID = "";
      this.theState = true;
      this.userRole = [];
    },

    openUpdatePanel(id, index) {
      this.updatePopupActive = true;
      this.theID = id;
      this.theIndex = index;
      var item = this.configlist.find(x => x[this.idname] === this.theID);

      // console.log("this.theID", this.theID);

      if (item != null) {
        this.configitem = item.username;
        this.dec = item.description;
        this.theState = item.active;
        this.userBranch = [];

        // var thelist = this.automapper(item.userBranch, ["branchId"]);

        // var newlist = [];
        if (item.userBranch !== null && item.userBranch !== undefined) {
          item.userBranch.forEach(x => {
            this.userBranch.push(x.branchId);
          });
        }

        // MAKE ROLE NAME to ARRAY
        var rolelist = [];
        for (var i = 0; i < item.roleName.length; i++) {
          rolelist[i] = item.roleName[i].roleName;
        }

        // console.log("rolelist[0] >>> ", rolelist[0]);

        this.userRole = rolelist[0];

        // MAKE ROLE NAME to ARRAY
      }
    },
    openRemovePanel(id, index) {
      this.removePopupActive = true;
      this.theID = id;
      this.theIndex = index;

      var item = this.configlist.find(x => x[this.idname] === this.theID);
      this.removeInfo = item.username;

      if (item != null) {
        this.theInfo = item.username;
        // console.log("ITEM IS EXISTING this.theID ", this.theID);
      }
    },

    closeUpdatePanel() {
      this.updatePopupActive = false;
      this.resetValue();
    },
    changeState(id, index) {
      // console.log("when change state idname >>>> ", this.idname);

      this.theID = id;
      this.theIndex = index;
      // this.configlist[this.theIndex].active = !this.configlist[this.theIndex]
      //   .active;
      this.theState = !this.configlist[this.theIndex].active;

      // console.log(
      //   "this.theID >>> ",
      //   this.theID,
      //   " >>> this.configlist[this.theIndex].active >>> ",
      //   this.configlist[this.theIndex].active
      // );

      /*****  BEGIN: AXIOS HTTP   *****/

      this.$http
        .put(
          this.$store.state.apiURL +
            "/api/" +
            this.apiname +
            "/changestate/" +
            this.theID,

          {
            state: this.theState
          },
          this.$store.state.auth.apiHeader
        )
        .then(response => {
          // console.log("CHANGE STATUS");
          if (response.status === 200) {
            // this.$vs.notify({
            //   title: "บันทึกสำเร็จ",
            //   position: "top-center",
            //   text: "ได้บันทึกข้อมูลสินค้าเรียบร้อยแล้ว",
            //   iconPack: "feather",
            //   icon: "icon-alert-circle",
            //   color: "success"
            // });
          } else if (response.status === 500) {
            this.$vs.notify({
              title: "เกิดข้อผิดพลาดในการทำงาน",
              position: "top-center",
              text: "เกิดข้อผิดพลาดในการทำงาน",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: "เกิดข้อผิดพลาดในการทำงาน",
            position: "top-center",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        });

      /*****  END: AXIOS HTTP   *****/
    },
    async update() {
      // this.$vs.loading({ type: "radius" });
      /*****  BEGIN: AXIOS HTTP   *****/
      // UPDATE USER
            if(this.configitem === null || this.configitem === undefined || this.configitem === ""  ){
        this.$swal({
          icon: 'warning',
          title: 'โปรดระบุชื่อผู้ใช้งานในรูปแบบอีเมล',
        });

        return ;
      }



      if(this.userRole.length <= 0){
        this.$swal({
          icon: 'warning',
          title: 'เลือกสิทธิ์ผู้ใช้งานอย่างน้อย 1 สิทธิ์',
        });

        return ;
      }
      if(this.userBranch.length <= 0){
        this.$swal({
          icon: 'warning',
          title: 'เลือกสาขาอย่างน้อย 1 สาขา',
        });

        return ;
      }


      var theroles = [];

      // for (let index = 0; index < this.userRole.length; index++) {
      //   var a_role = { roleName: this.userRole[index] };
      //   theroles.push(a_role);
      // }

      console.log("user this.userRole >> ",this.userRole)


      var a_role = { roleName: this.userRole };
      theroles.push(a_role);

      var thebranches = [];
      for (let index = 0; index < this.userBranch.length; index++) {
        var a_brn = { branchId: this.userBranch[index] };

        thebranches.push(a_brn);
      }

      console.log("BEFORE UPDATE thebranches >>> ", thebranches, "   theroles >>> ",theroles);

      let resp = null;

      try{
        resp = await this.$http
        .post(
          this.$store.state.apiURL + "/api/" + this.apiname + "/update",
          {
            username: this.configitem,
            roleName: theroles,
            branches: thebranches,
            active: this.theState
          },
          this.$store.state.auth.apiHeader
        );
      }
      catch(err){
          this.$vs.notify({
            title: "เกิดข้อผิดพลาดในการทำงาน",
            position: "top-center",
            text: err.data,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
          // console.log("CATCH ERROR :: response.data  ");
        }



         console.log(resp.status);
         if (resp.status === 200) {
            this.reload();
            // console.log("response.data UPDATE", response.data);
            this.$store.dispatch("auth/getAuth");
            this.updatePopupActive = false;
            setTimeout(() => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "บันทึกสำเร็จ",
                position: "top-center",
                text: "บันทึกข้อมูลผู้ใช้งานเรียบร้อยแล้ว",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success"
              });
            }, 100);
          } else {
            setTimeout(() => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "เกิดข้อผิดพลาดในการทำงาน",
                position: "top-center",
                text: "เกิดข้อผิดพลาดในการทำงาน",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger"
              });
            }, 100);
          }



        // .then(response => {
        //   // console.log(response.status);
        //   if (response.status === 200) {
        //     this.reload();
        //     // console.log("response.data UPDATE", response.data);
        //     this.$store.dispatch("auth/getAuth");
        //     this.updatePopupActive = false;
        //     setTimeout(() => {
        //       this.$vs.loading.close();
        //       this.$vs.notify({
        //         title: "บันทึกสำเร็จ",
        //         position: "top-center",
        //         text: "บันทึกข้อมูลผู้ใช้งานเรียบร้อยแล้ว",
        //         iconPack: "feather",
        //         icon: "icon-alert-circle",
        //         color: "success"
        //       });
        //     }, 1000);
        //   } else {
        //     setTimeout(() => {
        //       this.$vs.loading.close();
        //       this.$vs.notify({
        //         title: "เกิดข้อผิดพลาดในการทำงาน",
        //         position: "top-center",
        //         text: "เกิดข้อผิดพลาดในการทำงาน",
        //         iconPack: "feather",
        //         icon: "icon-alert-circle",
        //         color: "danger"
        //       });
        //     }, 1000);
        //   }
        // })
        // .catch(error => {
        //   // console.log("เกิดข้อผิดลาด");
        //   setTimeout(() => {
        //     this.$vs.loading.close();
        //     this.$vs.notify({
        //       title: "เกิดข้อผิดพลาดในการทำงาน",
        //       position: "top-center",
        //       text: error.message,
        //       iconPack: "feather",
        //       icon: "icon-alert-circle",
        //       color: "danger"
        //     });
        //   }, 1000);
        // });
      /*****  END: AXIOS HTTP   *****/
    },
    closeRemovePanel() {
      this.removePopupActive = false;
    },
    remove() {
      // console.log("this.configitem >>> ", this.configitem);

      this.$http
        .delete(
          this.$store.state.apiURL + "/api/" + this.apiname + "/" + this.theID,
          this.$store.state.auth.apiHeader
        )
        .then(response => {
          // console.log(response.status);
          if (response.status === 200) {
            this.$vs.notify({
              title: "ลบสำเร็จ",
              position: "top-center",
              text: "ได้ลบข้อมูลเรียบร้อยแล้ว",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success"
            });
            // console.log("200 :: response.data  ");
            this.removePopupActive = false;
            // this.configlist.splice(this.theIndex, 1);
            this.reload();
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: "เกิดข้อผิดพลาดในการทำงาน",
            position: "top-center",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
          // console.log("CATCH ERROR :: response.data  ");
        });
    }
  },
  async created() {
    this.branchList = await this.getAllBranch();

    this.branchList = this.automapper(this.branchList, [
      "branchId",
      "branchName",
      "flag1"
    ]);

    this.currentUser = localStorage.getItem('username');

     if(this.userBestRole === 'admin'){
        this.accessRight = [ "admin", "staff"];
     }
     else if(this.userBestRole === 'owner'){
        this.accessRight = ["owner" , "admin", "staff"];
     }

    // console.log("currentUser  >>> ", this.currentUser);

    // Http  LOAD ROELS
    try {
      const response = await this.$http.get(
        this.$store.state.apiURL + "/api/role",
        this.$store.state.auth.apiHeader
      );
      var rolelist = [];
      if (response.status === 200) {
        for (var i = 0; i < response.data.length; i++) {
          rolelist[i] = response.data[i].roleName;
        }
      }

      this.roles = rolelist;

      // console.log("this.roles", this.roles);
    } catch (error) {
      this.noticeError(error);
      // console.log("error", error);
    }

    /*****  BEGIN: AXIOS HTTP   *****/
    this.$http
      .get(
        this.$store.state.apiURL + "/api/" + this.apiname,
        this.$store.state.auth.apiHeader
      )
      .then(response => {
        if (response.status === 200) {
          // console.log("response.data >>*&(&%%$*%T(&*Y >>>> ", response.data);

          this.configlist = response.data;
        } else {
          this.$vs.notify({
            title: "ไม่สามารถโหลดข้อมูลได้",
            position: "top-center",
            text: "เกิดข้อผิดพลาดในการทำงาน",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        }
      })
      .catch(error => {
        this.$vs.notify({
          title: "ไม่สามารถโหลดข้อมูลได้",
          position: "top-center",
          text: error.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      });
    /*****  END: AXIOS HTTP   *****/
  }
};
</script>

<style>
.vs-component .vs-switch .vs-switch-success .vs-switch-active {
  width: 50px !important;
}
</style>
